<template>
  <div>
    Colors
    <label for="color-w"> <input type="checkbox" id="color-w" value="w" v-model="color" />W</label>
    <label for="color-b"> <input type="checkbox" id="color-b" value="u" v-model="color" />B</label>
    <label for="color-r"> <input type="checkbox" id="color-r" value="b" v-model="color" />R</label>
    <label for="color-g"> <input type="checkbox" id="color-g" value="r" v-model="color" />G</label>
    <label for="color-u"> <input type="checkbox" id="color-u" value="g" v-model="color" />U</label>
    <label for="color-c"> <input type="checkbox" id="color-c" value="colorless" v-model="color" />C</label>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      color: [],
    };
  },
};
</script>
