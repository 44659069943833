<template>
  <div>
    <span>
      <h1>{{ $route.params.id ? `Tierlist ${$route.params.id}` : this.title }}</h1>
      <set-select />
      <rarities />
      <colors />
    </span>
    <div id="unsortedCards"><MtgCard v-for="(_, index) in unsortedCards" :key="index" /></div>
    <div class="tiers">
      <div v-for="(tier, index) in tiers" :key="index">
        <h2>{{ tier.label }}</h2>
        <div>
          <MtgCard v-for="(_, index) in tier.cards" :key="index" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SetSelect from '../components/SetSelect.vue';
import Colors from '../components/Colors.vue';
import Rarities from '../components/Rarities.vue';
import MtgCard from '../components/MtgCard.vue';
import axios from 'axios';

export default {
  components: { SetSelect, Colors, Rarities, MtgCard },
  data: function() {
    return {
      title: 'New Tierlist',
      tiers: [
        { label: 'A', cards: [] },
        { label: 'B', cards: [] },
        { label: 'C', cards: [] },
        { label: 'D', cards: [] },
        { label: 'F', cards: [] },
      ],
      unsortedCards: [],
    };
  },
  methods: {
    saveTierlist: function() {
      axios.get('http://localhost:5000/tierlists/popular').then((response) => {
        this.popularTierlists = response.data.data;
      });
    },
  },
};
</script>
