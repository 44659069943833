<template>
  <div>
    Set
    <select name="set-select" id="set-select">
      <option v-for="s in sets" :value="s.code" :key="s.code">{{ s.name }}</option>
    </select>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      sets: [
        {
          code: 'ZNR',
          name: 'Zendikar Rising',
        },
      ],
    };
  },
  methods: {
    fetchSets() {
      fetch('https://api.scryfall.com/sets')
        .then((response) => response.json())
        .then((data) => {
          data = data.data.filter((set) => ['core', 'expansion', 'masters', 'draft_innovation'].includes(set.set_type));
          data.sort((s1, s2) => s2.released_at > s1.released_at);
          this.sets = data;
          console.log(data);
        });
    },
  },
  mounted() {
    this.fetchSets();
  },
};
</script>
