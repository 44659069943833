<template>
  <div id="app">
    <main>
      <div class="content">
        <tierlist-list :list="popularTierlists" :title="'Popular Tierlists'" />
        <tierlist-list :list="newTierlists" :title="'New Tierlists'" />
      </div>
    </main>
  </div>
</template>

<script>
import 'mana-font';
import axios from 'axios';
import TierlistList from '../components/TierlistList.vue';

export default {
  data: function() {
    return {
      newTierlists: [],
      popularTierlists: [],
    };
  },
  mounted() {
    axios.get('http://localhost:5000/tierlists/new').then((response) => {
      this.newTierlists = response.data.data;
    });
    axios.get('http://localhost:5000/tierlists/popular').then((response) => {
      this.popularTierlists = response.data.data;
    });
  },
  components: {
    TierlistList,
  },
};
</script>

<style lang="css">
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
