<template>
  <div>
    Rarities
    <label for="rarity-c"> <input type="checkbox" id="rarity-c" value="c" v-model="rarity" />C</label>
    <label for="rarity-b"> <input type="checkbox" id="rarity-b" value="u" v-model="rarity" />U</label>
    <label for="rarity-r"> <input type="checkbox" id="rarity-r" value="r" v-model="rarity" />R</label>
    <label for="rarity-m"> <input type="checkbox" id="rarity-m" value="m" v-model="rarity" />M</label>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      rarity: [],
    };
  },
};
</script>
