<template>
  <div class="list">
    <h2>{{ title }}</h2>
    <ul>
      <li v-for="(tl, index) in list" :key="index">{{ tl.title }}</li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ['list', 'title'],
};
</script>
